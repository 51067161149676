import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { request } from '@/utils/request'

import { getCookie } from '@/utils/common'
Vue.use(VueI18n)

const i18n = new VueI18n()
const loadedLanguages: string[] = []
const lang = getCookie('lang') || 'ru'
loadLanguageAsync(lang)

function setI18nLanguage (lang: string) {
  i18n.locale = lang

  const html = document.querySelector('html')
  if (html) {
    html.setAttribute('lang', lang)
  }

  return lang
}

export async function loadLanguageAsync (lang: string) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  try {
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    const dictinary = await import(/* webpackChunkName: "i18n/[request]" */ `./${lang}.ts`)
    i18n.setLocaleMessage(lang, await dictinary.default())
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export async function loadBrandingTranslations (branding: any) {
  if (!branding || !branding.translations) {
    return
  }

  const lang = i18n.locale
  // Получаем текущие переводы для указанного языка
  const currentMessages = i18n.getLocaleMessage(lang) || {}

  try {
    // Загрузим условно сегодняшнюю версию брендированного словаря переводов СДО.
    const response = await request(`/${branding.translations}/${lang}.json?_ts=${(new Date()).toISOString().substring(0, 10)}`)
    addTranslations(lang, { $sdo: Object.assign(currentMessages.$sdo, response) })
  } catch (e) {
    //
  }
}

function addTranslations (lang: string, translations: object) {
  // Получаем текущие переводы для указанного языка
  const currentMessages = i18n.getLocaleMessage(lang) || {}

  // Объединяем текущие переводы с новыми
  i18n.setLocaleMessage(lang, { ...currentMessages, ...translations })
}

export default i18n
